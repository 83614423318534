import { EDataLayerEvents, EDataLayerEventCategories, TDataLayerObj } from '@mtsdengi/common'

export enum EEvents {
  PAGE_NOT_FOUND_VIEW = 'notFoundView',
  MAIN_PAGE_VIEW = 'mainView',
  BANNER_CLICK = 'banner_click',
  BANNER_BUTTON_CLICK = 'banner_button_click',
  BANNER_SHOW = 'banner_show',
  WIDGET_CLICK = 'widget_click',
  WIDGET_BUTTON_CLICK = 'widget_button_click',
  WIDGET_SHOW = 'widget_show',
}

export enum EDataLayerEventsLocal {
  MTS_DENGI_MAIN = 'mtsDengiMain',
}

export enum EDataLayerEventCategoriesLocal {
  MTS_DENGI = 'mtsDengi',
  OSHIBKI = 'oshibki',
  ADVERTISING = 'advertising',
  WIDGET = 'widget',
}

export enum EDataLayerEventLabel {
  MAIN_PAGE = 'main_page',
  NOT_FOUND = 'not_found',
}

export type TDataLayerObjLocal = Omit<TDataLayerObj, 'event' | 'eventCategory'> & {
  event: EDataLayerEventsLocal | EDataLayerEvents
  eventCategory: EDataLayerEventCategories | EDataLayerEventCategoriesLocal
}
