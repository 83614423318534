import React, { FC, useCallback, useEffect, useState } from 'react'
import { theme } from '@mtsdengi/ui-kit'
import {
  Grid,
  useDataLayer,
  SmartSpacer,
  unAuthTheme,
  AppBlock,
  Layout,
  Observed,
  getDataLayerFormattedText,
  TCardFeature,
  TAppBlock,
} from '@mtsdengi/common'
import { EEvents } from '@shared/types'
import { dataLayerBaseData as baseData } from '@shared/config'
import { HomeCarousel, THomeCarousel, TObservedCardFeature, ObservedCardFeature } from '@entities'
import { Premium, TPremiumProps } from '@features'
import { dataLayerEvents } from './lib'
import { ECardType, isCardType, TCardBlock, TPageData } from './types'
import { Styled } from './styled'

export const MainPage: FC<TPageData> = ({ layout, carousel, cards }) => {
  const [isVisibleHomeCarousel, setIsVisibleHomeCarousel] = useState(false)
  const { dataLayerDispatcher } = useDataLayer(dataLayerEvents, { baseData })
  const onChangeSlideCallback: THomeCarousel['onChangeSlide'] = useCallback(
    ({ headingText, position, id }) =>
      headingText &&
      isVisibleHomeCarousel &&
      dataLayerDispatcher(EEvents.BANNER_SHOW, {
        eventLabel: getDataLayerFormattedText(headingText),
        bannerName: getDataLayerFormattedText(headingText),
        eventContext: String(position),
        bannerId: String(id),
        eventContent: 'slider',
      }),
    [dataLayerDispatcher, isVisibleHomeCarousel]
  )
  const onClickCarouselButtonHandle: THomeCarousel['onClickCarouselButton'] = useCallback(
    ({ headingText, position, id }) =>
      headingText &&
      dataLayerDispatcher(EEvents.BANNER_BUTTON_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
        bannerName: getDataLayerFormattedText(headingText),
        eventContext: String(position),
        bannerId: String(id),
        eventContent: 'slider',
      }),
    [dataLayerDispatcher]
  )
  const onClickCarouselSlideHandle: THomeCarousel['onClickCarouselSlide'] = useCallback(
    ({ headingText, position, id }) =>
      headingText &&
      dataLayerDispatcher(EEvents.BANNER_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
        bannerName: getDataLayerFormattedText(headingText),
        eventContext: String(position),
        bannerId: String(id),
        eventContent: 'slider',
      }),
    [dataLayerDispatcher]
  )

  const handleShowCard = useCallback(
    (headingText: string) => {
      dataLayerDispatcher(EEvents.WIDGET_SHOW, {
        eventLabel: getDataLayerFormattedText(headingText),
      })
    },
    [dataLayerDispatcher]
  )

  const handleShowFeatureCard: TObservedCardFeature['onAfterVisibleCard'] = useCallback(
    ({ headingText }) => {
      handleShowCard(headingText)
    },
    [handleShowCard]
  )

  const handleClickCard = useCallback(
    (headingText: string) => {
      dataLayerDispatcher(EEvents.WIDGET_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
      })
    },
    [dataLayerDispatcher]
  )

  const handleClickCardButton = useCallback(
    (headingText: string) => {
      dataLayerDispatcher(EEvents.WIDGET_BUTTON_CLICK, {
        eventLabel: getDataLayerFormattedText(headingText),
      })
    },
    [dataLayerDispatcher]
  )

  useEffect(() => {
    dataLayerDispatcher(EEvents.MAIN_PAGE_VIEW)
  }, [dataLayerDispatcher])

  return (
    <Layout {...layout}>
      <Observed
        onAfterVisible={() => setIsVisibleHomeCarousel(true)}
        onAfterInvisible={() => setIsVisibleHomeCarousel(false)}
      >
        <HomeCarousel
          {...carousel}
          onChangeSlide={onChangeSlideCallback}
          onClickCarouselButton={onClickCarouselButtonHandle}
          onClickCarouselSlide={onClickCarouselSlideHandle}
        />
      </Observed>
      <SmartSpacer spaceMob={theme.spacings.md} spaceTablet={unAuthTheme.spacings.md4} />
      <Grid>
        {cards.items?.map(({ cell, ...cardFeatureProps }) => (
          <Styled.Cell key={cardFeatureProps.id} {...cell}>
            {{
              [ECardType.CARD_FEATURE]: isCardType<TCardBlock<TCardFeature>>(
                cardFeatureProps,
                ECardType.CARD_FEATURE
              ) && (
                <ObservedCardFeature
                  commonProps={{
                    ...cards.commonFeatureCardsProps,
                    ...(cardFeatureProps.href && cards.animationProps),
                  }}
                  cardProps={cardFeatureProps}
                  onAfterVisibleCard={handleShowFeatureCard}
                  onClickCard={() => handleClickCard(cardFeatureProps.heading.text)}
                  onClickCardButton={() => handleClickCardButton(cardFeatureProps.heading.text)}
                />
              ),
              [ECardType.PREMIUM]: isCardType<TCardBlock<TPremiumProps>>(cardFeatureProps, ECardType.PREMIUM) && (
                <Observed onAfterVisible={() => handleShowCard(cardFeatureProps.heading)}>
                  <Premium
                    {...cardFeatureProps}
                    onClickButton={() => handleClickCardButton(cardFeatureProps.heading)}
                  />
                </Observed>
              ),
              [ECardType.APP_BLOCK]: isCardType<TCardBlock<TAppBlock>>(cardFeatureProps, ECardType.APP_BLOCK) && (
                <Observed onAfterVisible={() => handleShowCard(cardFeatureProps.heading.text)}>
                  <AppBlock {...cardFeatureProps} onClickButton={({ name }) => handleClickCardButton(name)} />
                </Observed>
              ),
            }[cardFeatureProps.type] ?? null}
          </Styled.Cell>
        ))}
      </Grid>
    </Layout>
  )
}
