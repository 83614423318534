import { GetStaticProps } from 'next'
import { getCommonRequest, TLayout } from '@mtsdengi/common'
import { REVALIDATE_TIME } from '@shared/config'
import { TPageData } from '../../types'

export const getStaticPropsMainPage: GetStaticProps<TPageData> = async () => {
  const [
    { data: layout },
    {
      data: { meta, microMarkup, ...pageData },
    },
  ] = await Promise.all([
    getCommonRequest<TLayout>('/mtsdengi/header-footer', undefined, undefined, ['navigation', 'logo']),
    getCommonRequest<TPageData>('/mtsdengi/main-page/', undefined, undefined, [
      'cards',
      'carousel',
      'meta',
      'microMarkup',
    ]),
  ])

  return {
    props: {
      meta,
      layout,
      microMarkup,
      ...pageData,
    },
    revalidate: REVALIDATE_TIME,
  }
}
