import styled, { css } from 'styled-components'
import { Cell as CellCommon, getResponsiveStyles } from '@mtsdengi/common'
import { TCell } from '@shared/types'

const Cell = styled(CellCommon)<TCell>`
  ${({ padding }) => css`
    ${padding && getResponsiveStyles('padding', padding)};
  `}
`

export const Styled = { Cell }
