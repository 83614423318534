import { TAppBlock, TCard, TCardFeature, TLayout } from '@mtsdengi/common'
import { TCell } from '@shared/types'
import { THomeCarousel } from '@entities'
import { TPremiumProps } from '@features/premium'

export enum ECardType {
  CARD_FEATURE = 'cardFeature',
  PREMIUM = 'premium',
  APP_BLOCK = 'appBlock',
}

export type TMicroMarkup = {
  '@context': string
  '@type': string
  name: string
  url: string
}

type THasCell<T> = { cell?: TCell } & T

export type TCardBlock<T> = { type: ECardType; id: number } & T

type TCardItem = TCardBlock<TCardFeature | TPremiumProps | TAppBlock>

export type TPageData = {
  meta: Record<string, unknown>
  microMarkup: TMicroMarkup[]
  layout: TLayout
  carousel: THomeCarousel
  cards: {
    commonFeatureCardsProps?: THasCell<TCardFeature>
    items: THasCell<TCardItem>[]
    animationProps?: Pick<TCard, 'transition' | 'hoverBehavior'>
  }
}

/**
 * Проверка типа карточки
 * @param card - данные карточки
 * @param type  - тип
 * @returns
 */
export const isCardType = <T extends TCardBlock<unknown>>(card: T | TCardItem, type: ECardType): card is T =>
  card?.type === type
