import { isObject } from '../isObject'

/**
 * Функция для мержа общих пропсов с определенными для конкретного элемента, во избежании копипаста по всему массиву элементов
 * Мержит только объекты первого уровня вложенности
 * @example
 * mergeProps({ heading: { marginBottomMob: 16 } }, { heading: { text: 'test', fontWeightMob: 500 } })
 * => { heading: { marginBottomMob: 16, text: 'test', fontWeightMob: 500 } }
 */
export const mergeProps = <T extends object>(commonProps: Partial<T>, currentProps: T): T =>
  Object.entries(commonProps || {}).reduce(
    (acc, [key, value]) => {
      const accCurrentValue = acc[key]

      if (key in acc && isObject(value) && isObject(accCurrentValue)) {
        acc[key] = { ...value, ...accCurrentValue }
      } else {
        acc[key] = value
      }

      return acc
    },
    { ...currentProps }
  )
